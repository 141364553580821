#Main {
    // Component level values
    --black-text: rgb(41, 41, 41);

    position: relative;
    color: var(--black-text);
    min-height: 100%;
    padding-bottom: 5rem;

    header {
        text-align: center;
        margin: 3rem auto;
        width: 95%;
        h1 {
            font-size: 1rem;
            font-weight: 500;
            margin-bottom: 0.5rem;
        }
    }
}

#Form {
    width: 90%;
    margin: auto;

    #ContactInformation, #BusinessInformation {
        margin-top: 2rem;
        
        h3 {
            font-weight: 400;
            padding-bottom: .5rem;
            margin-bottom: 1.5rem;
            border-bottom: 1px solid #ebecec;
        }
    }
}

#BankSelector, #PosSelector, #ProcessorSelector { 
    margin-top: .25rem; 
}

.onboarding-dropdown-selector {
    margin-bottom: 2rem;
}

#PhoneInput { 
    margin-top: 1rem; 
    label { 
        display: inline-block;
        margin-bottom: .25rem;
    } 
}

.state-zip {
    display: flex;
    width: 50%;
}

.submit-button-wrapper {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
        font-size: 1.15rem;
        font-weight: 300;
        letter-spacing: 0.09em;
        padding: 1rem 3rem;
        background: #4482DE;
        border: none;
        border-radius: 5px;
        color: white;
        box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.255);
        cursor: pointer;
    }
}

@media screen and (min-width: 660px) {
    #Form { width: 50%; }
}

@media screen and (min-width: 1226px) {
    #Form { width: 30%; }
}