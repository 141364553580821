label { display: inherit };
label span { font-size: .75rem; color: #8a8a8a; }

.text-input-component, .zipcode-input-component {
    font-size: 1rem;
    min-height: 2.37rem;
    margin-top: .25rem;
    border-radius: 4px;
    border: 1px solid hsl(0, 0%, 80%);
    padding-left: .5rem;
    &:focus {
        outline: none;
        border: 1px solid #2684FF;
        box-shadow: 0 0 0 1px #2684FF;
        background: #e8f2ff;
    }
}

// .text-input-component { width: 100% }

.phone-input {
    &:focus {
        outline: none;
        border: 1px solid #2684FF;
        box-shadow: 0 0 0 1px #2684FF;
        background: #e8f2ff;
    }
}

.zipcode-input-component {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    &[type=number] {
    -moz-appearance: textfield;
    }
}

#ZipCode {
    label { display: inherit };
}
