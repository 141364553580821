#Confirmation {
    text-align: center;
}

.center-text {
    margin-top: 5rem;
    h1, h2 { 
        font-weight: 400; 
        margin-bottom: 1rem;
        color: rgb(41, 41, 41);
    }

    button {
        font-size: 1.15rem;
        font-weight: 300;
        letter-spacing: 0.09em;
        padding: 1rem 3rem;
        background: #4482DE;
        border: none;
        border-radius: 5px;
        color: white;
        box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.255);
        cursor: pointer;
    }

}