#HeaderBar {
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 0 6px rgba(40, 40, 40, 0.299);
    height: 7vh;
    background-color: white;
    z-index: 100;
}