* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-family: 'Inter', sans-serif;
    height: 100%;
}

#root, body {
    position: relative;
    height: 100%;
}