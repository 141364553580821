#Footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    padding-top: 10px;
    text-align: center;
    background-color: #5E5E5E;
    z-index: 100;
    span {
        font-size: .5rem;
        color: white;
    }
}